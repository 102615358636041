exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-appstores-tsx": () => import("./../../../src/pages/appstores.tsx" /* webpackChunkName: "component---src-pages-appstores-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-global-privacy-policy-tsx": () => import("./../../../src/pages/global-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-global-privacy-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobile-video-tsx": () => import("./../../../src/pages/mobile-video.tsx" /* webpackChunkName: "component---src-pages-mobile-video-tsx" */),
  "component---src-pages-us-privacy-policy-tsx": () => import("./../../../src/pages/us-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-us-privacy-policy-tsx" */)
}

